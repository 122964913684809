/* 我的采购 */
<template>
  <div class="wrapper">
    <el-container style="height: 100%">
      <el-header height="auto">
        <headerCode />
        <div class="menu-info-box">
          <div class="menu-info">
            <span v-for="(it, index) in pageNav" :key="'pagenav' + index">
              {{ it.name }}
              <template v-if="index < pageNav.length - 1">></template>
            </span>
          </div>
        </div>
        <!-- 基础信息 -- 目前只有2部分，所以可以放这里，后期有改动  -->
        <!-- <userInfoCom /> -->
        <div class="breadcrumb" style="width:1200px; margin:0 auto">
          我的采购 /
          <span style="color:#000; font-weight: bolder;">{{ $route.name }}</span>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import headerCode from "@/views/mySelf/components/header.vue";
import { mapGetters } from "vuex";
export default {
  name: "myProcured",
  components: { headerCode },
  computed: {
    ...mapGetters(["pageNav"]),
  },
  data() {
    return {};
  },
  created() {
    this.$store.commit("changeChoseType", 5);
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  padding: 10px 50px;
}
.wrapper {
  height: 95vh;
}
</style>
